@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "ClearSansDiplay", Calibri, sans-serif;
}
html, body {
	overflow-x:hidden;
  font-family: "ClearSansDiplay";
}
@font-face {
  font-family: "ClearSansDiplay";
  src: url('./fonts/ClearSansDisplayRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ClearSansDiplay";
  src: url('./fonts/ClearSansTextRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ClearSansDisplay";
  src: url('./fonts/ClearSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "ClearSansDisplay";
  src: url('./fonts/ClearSans-Medium.ttf') format('truetype');
  font-weight: medium;
  font-style: normal;
}
body {
  background-color: #F7F7F7; 
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

:root {
  --notionblue: #103FB8;
  --blacktext: #222120;
  --skyblue: #0887FF;
  --graytext: #626C72;
  --darkgray: #3A3A3A;
}

@layer base {
  @font-face {
    font-family: "ClearSansDisplay";
    src: url('./fonts/ClearSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  html {
    @apply scroll-smooth
  }
  body {
    @apply font-[ClearSansDisplay]
  }
  button {
    @apply px-6 py-2 xl:px-10 xl:py-4 text-white rounded-full xl:font-semibold
  }
  .button-theme {
    @apply inline-flex items-center justify-center p-[3px] overflow-hidden text-sm font-medium text-white rounded-full bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-white group-hover:to-white hover:text-[var(--notionblue)] hover:shadow-lg
  }
  /* mb-2 me-2 */
  .btn-span-theme {
    @apply text-lg font-semibold transition-all ease-in-out duration-300 bg-transparent rounded-full group-hover:bg-white group-hover:bg-opacity-100
  }
  .title-theme {
    @apply text-center pb-1 tracking-tighter text-6xl bg-gradient-to-r from-[#1D1D1F] from-[10%] to-[#0887FF] bg-clip-text text-transparent
  }
}